import clsx, { ClassValue } from "clsx";
import { Brand, Brands } from "../types";

/**
 * A typeguard for checking if the brand is a correct brand.
 *
 * @param brand The string to check.
 * @returns Whether the brand parameter is a brand string.
 */
export const isBrand = (brand: Brand | string | ClassValue): brand is Brand => {
  return Brands.includes(brand as Brand);
};

/**
 * Creates the brand class name from the corresponding brand name.
 *
 * @param brand The brand name.
 * @returns The brand class name string.
 */
export const getBrandClass = (brand: Brand | ClassValue = "default") => {
  return isBrand(brand) ? `brand--${brand}` : brand;
};

/**
 * A wrapper around {@link clsx} that adds a brand class name to the front of
 * the class name and returns a class name string with the correct brand class.
 *
 * @param brand The brand name.
 * @param inputs Additional class names and class name logic.
 * @returns The class name string with branding.
 */
export function bclsx(
  brand: Brand | ClassValue = "default",
  ...inputs: ClassValue[]
): string {
  return clsx(getBrandClass(brand), ...inputs);
}
