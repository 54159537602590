import React from "react";

import ModalActionProps from "./ModalAction.props";
import { bclsx } from "../../../../util";
import { Button } from "../../Button";
import { useBrand } from "../../../../hooks";

const ModalAction = ({
  brand,
  positive = true,
  onClick,
  className,
  children,
  ...rest
}: ModalActionProps) => {
  const b = useBrand(brand);
  return (
    <Button
      className={bclsx(b, "modal-action", "modal__action", className, {
        open: open,
      })}
      primary={positive}
      secondary={!positive}
      onClick={onClick}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default ModalAction;
