import { BrandProvider, Button, Modal } from "@golollc/ui";
import { useState } from "react";

const App = () => {
  const [open, setOpen] = useState(false);
  return (
    <BrandProvider brand="golo-foods">
      <Button
        label="Open Modal"
        primary
        large
        small={false}
        onClick={() => setOpen(true)}
      />
      <Modal
        title="Clear Selection"
        open={open}
        onClose={() => setOpen(false)}
        closeIcon
        actions={[
          { positive: true, label: "Yes" },
          {
            positive: false,
            label: "Cancel",
            onClick: () => setOpen(false),
          },
        ]}
      >
        <span className="text-center">
          Are you sure you want to clear your cart?
        </span>
      </Modal>
    </BrandProvider>
  );
};

export default App;
