import React, { useEffect, useState } from "react";

import ButtonProps from "./Button.props";
import { LoadingIndicator } from "../Icon";
import { bclsx } from "../../../util";
import { useBrand } from "../../../hooks";

const Button = ({
  brand,
  label,
  href,
  gaId,
  disabled = false,
  primary = true,
  secondary,
  small = true,
  large,
  className,
  onClick,
  onDisabledClick,
  loading,
  children,
  ...rest
}: ButtonProps) => {
  const As = href === undefined ? "button" : "a";
  const b = useBrand(brand);

  const [virtualPageview, setVirtualPageView] = useState<string | undefined>();

  useEffect(() => {
    if (gaId) {
      const gaIdHandleized = gaId?.replace(/\s+/g, "-").toLowerCase();
      setVirtualPageView("/virtual/" + gaIdHandleized);
    }
  }, [gaId]);

  const labelValue = loading ? <LoadingIndicator brand={b} /> : label;

  return (
    <As
      className={bclsx(b, "gui", "button", className, {
        "button--primary": primary && !secondary,
        "button--secondary": secondary && !primary,
        "button--small": small,
        "button--large": large,
        "button--disabled": disabled,
      })}
      href={href}
      aria-disabled={disabled}
      // virtual page view if gaId is defined
      {...(virtualPageview
        ? {
            "data-virtual-key": "enter",
            "data-virtual-pageview": virtualPageview,
          }
        : {})}
      // only have on-click if button is not disabled
      {...(!disabled ? { onClick } : {})}
      onClick={!disabled ? onClick : onDisabledClick || undefined}
      {...rest}
    >
      {labelValue ?? children}
    </As>
  );
};

export default Button;
