import React, { useCallback, useReducer } from "react";
import BrandContext from "./context";
import reducer from "./reducer";
import { Brand } from "../../types";

const BrandProvider = ({
  brand,
  children,
}: React.PropsWithChildren<{ brand: Brand }>) => {
  const [state, dispatch] = useReducer(reducer, { brand });

  /**
   * Sets the brand.
   */
  const setBrand = useCallback(
    (brand: Brand) => {
      dispatch({ type: "setBrand", payload: brand });
    },
    [dispatch]
  );

  return (
    <BrandContext.Provider value={{ brand: state.brand, setBrand }}>
      {children}
    </BrandContext.Provider>
  );
};

export default BrandProvider;
