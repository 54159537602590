import React from "react";
const CloseIcon = () => {
  return (
    <svg
      id="Exit_Button"
      data-name="Exit Button"
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <circle
        id="Ellipse_181"
        className="exit-button-circle"
        data-name="Ellipse 181"
        cx="18"
        cy="18"
        r="18"
        fill="#f7f7f7"
      />
      <g id="close-24px" transform="translate(6 6)">
        <path
          id="Path_728"
          data-name="Path 728"
          d="M0,0H24V24H0Z"
          fill="none"
        />
        <path
          id="Path_729"
          className="exit-button"
          data-name="Path 729"
          d="M18.3,5.71a1,1,0,0,0-1.41,0L12,10.59,7.11,5.7A1,1,0,0,0,5.7,7.11L10.59,12,5.7,16.89A1,1,0,0,0,7.11,18.3L12,13.41l4.89,4.89a1,1,0,0,0,1.41-1.41L13.41,12,18.3,7.11A1,1,0,0,0,18.3,5.71Z"
          fill="#006faf"
        />
      </g>
    </svg>
  );
};

export default CloseIcon;
