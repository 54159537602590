import { Reducer } from "react";
import { BrandAction, IBrandContextState } from "../../types";

const reducer: Reducer<IBrandContextState, BrandAction> = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case "setBrand":
      return { ...state, brand: payload };
    default:
      throw new Error("Unsupported reducer action");
  }
};

export default reducer;
