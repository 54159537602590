import React from "react";

import ModalProps from "./Modal.props";
import { bclsx } from "../../../util";
import { Button } from "../Button";
import { ModalAction } from "./ModalAction";
import CloseIcon from "../Icon/CloseIcon";
import { useBrand } from "../../../hooks";

const Modal = ({
  brand,
  title,
  open = false,
  closeIcon = true,
  actions,
  onClose,
  className,
  children,
  ...rest
}: ModalProps) => {
  const As = "div";
  const b = useBrand(brand);

  return (
    <As
      className={bclsx(b, "gui", "modal", className, {
        "modal--open": open,
      })}
      aria-hidden={!open}
      {...rest}
    >
      <div className="modal__dialog">
        <div className="modal__dialog__content">
          <div className="modal__header">
            <div className="modal__header__title__wrapper">
              <h2 className="modal__header__title">{title}</h2>
              {closeIcon && (
                <div className="modal__icon--close__wrapper">
                  <Button className="modal__icon--close" onClick={onClose}>
                    <CloseIcon></CloseIcon>
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className="modal__body">{children}</div>
          <div className="modal__action__wrapper">
            {actions?.map((action, index) => (
              <ModalAction {...action} key={`action-${index}`}></ModalAction>
            ))}
          </div>
        </div>
      </div>
    </As>
  );
};

export default Modal;
