/** Represents all supported GOLO plans. */
export const Brands = [
  "golo",
  "mygolo",
  "at",
  "golo-foods",
  "default",
] as const;

/** Represents a supported GOLO brand. */
export type Brand = typeof Brands[number];
