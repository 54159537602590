import React from "react";
import LoadingIndicatorProps from "./LoadingIndicator.props";
import { bclsx } from "../../../../util";

// TODO: color gets 45.45% darker on each keyframe

const LoadingIndicator = ({
  id,
  brand,
  width = "225px",
  height = "23px",
}: LoadingIndicatorProps) => {
  return (
    <div id={id} className={bclsx(brand, "loading-indicator")}>
      <svg
        id="dots"
        width={width}
        height={height}
        viewBox="0 20 210 23"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="loading-circles">
            <circle id="circle1" cx="55" cy="30" r="9.5"></circle>
            <circle id="circle2" cx="80" cy="30" r="9.5"></circle>
            <circle id="circle3" cx="105" cy="30" r="9.5"></circle>
            <circle id="circle4" cx="130" cy="30" r="9.5"></circle>
            <circle id="circle5" cx="155" cy="30" r="9.5"></circle>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default LoadingIndicator;
